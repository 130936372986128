<template>
  <div>
    <div class="facilityBox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="待审核" name="1">
          <auditAgency ref="auditAgency" v-if="activeName == 1"></auditAgency>
        </el-tab-pane>
        <el-tab-pane label="待设置" name="2">
          <notSetAgency
            ref="notSetAgency"
            v-if="activeName == 2"
          ></notSetAgency>
        </el-tab-pane>
        <!-- <el-tab-pane label="已设置" name="3">
          <setAgency ref="setAgency" v-if="activeName == 3"></setAgency>
        </el-tab-pane> -->
        <el-tab-pane label="待同意" name="4">
          <noAgreeAgency
            ref="noAgreeAgency"
            v-if="activeName == 4"
          ></noAgreeAgency>
        </el-tab-pane>
        <el-tab-pane label="已同意" name="5">
          <agreeAgency
            ref="agreeAgency"
            v-if="activeName == 5"
            :type="'agree'"
          ></agreeAgency>
        </el-tab-pane>
        <el-tab-pane label="已合作" name="7">
          <agreeAgency
            ref="agreeAgency"
            v-if="activeName == 7"
            :type="'cooper'"
          ></agreeAgency>
        </el-tab-pane>
        <el-tab-pane label="审核失败" name="6">
          <auditAgency
            ref="auditAgency"
            :auditError="true"
            v-if="activeName == 6"
          ></auditAgency>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import auditAgency from "@/views/platform/auditInfo/auditAgency/noAuditAgency";
import setAgency from "@/views/platform/auditInfo/auditAgency/setAgency";
import notSetAgency from "@/views/platform/auditInfo/auditAgency/noSetAgency";
import agreeAgency from "@/views/platform/auditInfo/auditAgency/agreeAgency";
import noAgreeAgency from "@/views/platform/auditInfo/auditAgency/noAgreeAgency";
export default {
  data() {
    return {
      activeName: "1",
    };
  },
  components: {
    auditAgency,
    setAgency,
    notSetAgency,
    agreeAgency,
    noAgreeAgency,
  },
  watch: {
    activeName(val) {
      setTimeout(() => {
        if (val == 1 || val == 6) {
          this.$refs.auditAgency.GetEnterprise();
        }
        if (val == 2) {
          this.$refs.notSetAgency.ByAgentLists();
        }
        if (val == 3) {
          this.$refs.setAgency.ByAgentLists();
        }
        if (val == 4) {
          this.$refs.noAgreeAgency.ByAgentLists();
        }
        if (val == 5) {
          this.$refs.agreeAgency.ByAgentLists();
        }
        if (val == 7) {
          this.$refs.agreeAgency.ByAgentLists();
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
